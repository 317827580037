<template>
    <div
            class="course-item"
            :key="courseData.id"
    >
      <template v-if="courseData.course_mode === 4">
        <div class="can_enter" @click="showVideo(courseData)">
          <div class="icon">
            <i
                    v-if="courseData.course_mode === 4"
                    class="iconfont icon-papa-vedio cursor_pointer"
            ></i>
          </div>
          <div class="course-desc cursor_pointer">
            <div class="first-line">
              <div class="course-mode">[ 抢先听 ]</div>
              <div>
                {{ courseData.title }}
              </div>
            </div>
            <div class="second-line">
              视频时长 {{ formatSecond(courseData.video_duration) }} 分钟
            </div>
          </div>
        </div>
        <div
                class="button_jump-to-exam"
                v-if="courseData.exercise_url"
                @click="openPage(courseData.exercise_url)"
        >
          <i class="iconfont icon-papa-practice">随堂练习 </i>
        </div>
      </template>
      <template v-else>
        <div class="left">
          <div class="icon">
            <i
                    v-if="courseData.course_mode === 1"
                    class="index_icon"
            >{{ liveNum() }}</i>
            <i
                    v-if="courseData.course_mode === 2"
                    class="iconfont icon-papa-recording"
            ></i>
            <i
                    v-if="courseData.course_mode === 3"
                    class="iconfont icon-papa-vedio"
            ></i>
          </div>
          <div class="course-desc">
            <div class="first-line">
              <div class="course-mode" v-if="courseData.course_mode === 1">
                [ 直播 ]
              </div>
              <div class="course-mode" v-if="courseData.course_mode === 2">
                [ 录播 ]
              </div>
              <div class="course-mode" v-if="courseData.course_mode === 3">
                [ 视频 ]
              </div>
              <div>
                {{ courseData.title }}
              </div>
            </div>
            <div class="second-line" v-if="courseData.course_mode === 1">
               {{ liveFormatMoment(courseData.started_at, courseData.ended_at) }}
            </div>
            <div class="second-line" v-if="courseData.course_mode === 2">
              视频时长 {{ recordedDuration(courseData.video_duration) }}
            </div>
            <div class="second-line" v-if="courseData.course_mode === 3">
              视频时长 {{ formatSecond(courseData.video_duration) }}
            </div>
          </div>
        </div>
        <div
                class="button_jump-to-exam"
                v-if="courseData.exercise_url"
                @click="openPage(courseData.exercise_url)"
        >
          <i class="iconfont icon-papa-practice">随堂练习 </i>
        </div>
      </template>
    </div>
</template>

<script>
  import {formatMoment, openPage, liveFormatMoment, recordedDuration} from '@/utils/utils'
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'
  export default {
    name: 'course-item',
    props: {
      courseData: {
        type: Object,
        default: () => {
          return {}
        }
      },
      courseIndex: {
        type: String,
        required: true
      }
    },
    computed:{
      ...mapState({
        videoDialog: ( state ) => state.user.videoDialog,
        isLogin: ( state ) =>
                state.user.authorization && state.user.authorization.access_token,
      }),
    },
    methods: {
      showVideo ( child ) {
        if (this.isLogin) {
          this.setCurrentVideo(child)
          // todo 免费视频跳知乎
          this.setVideoDialog(true)
        } else {
          this.$message.error('请登录后观看~')
          this.setLoginDialog({ status: true })
        }
      },
      ...mapActions(['setVideoDialog', 'setCurrentVideo', 'setLoginDialog']),
      formatMoment,
      openPage,
      liveFormatMoment,
      recordedDuration,
      liveNum() {
        let newNum = this.courseIndex + 1
        if(newNum < 10 ) {
          return '0' + newNum
        }else {
          return newNum
        }
        
      },

      formatSecond(second) {
        let minutes = moment.duration(second, 'seconds').get('minutes')
        let hour = moment.duration(second, 'seconds').get('hour')
        if (hour) {
          return hour + '小时' + minutes + '分钟'
        } else {
          return minutes + '分钟'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .index_icon {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: 400 !important;
    color: #999999;
    font-style: normal;
    line-height: 42px;
  }
  .course-item {
    display: flex;
    margin: 0 40px;
    border-bottom: 1px solid #e6e6e6;
    //padding: 10px 0;
    justify-content: space-between;
    align-items: center;

    .can_enter,
    .left {
      width: 100%;
      display: flex;
      padding: 20px 0;

      .cursor_pointer {
        cursor: pointer;
      }
    }

    .icon {
      font-size: 25px;
      margin-right: 20px;
    }
    a,
    div {
      display: flex;

      & > .icon {
        font-size: 25px;
        margin-right: 20px;
      }
    }

    .course-desc {
      font-size: @fontSizeCon;
      color: #333333;
      font-weight: 400;
      flex-direction: column;

      .first-line {
        display: flex;

        .course-mode {
        }
      }
    }

    .button_jump-to-exam {
      cursor: pointer;
      width: 116px;
      height: 32px;
      border-radius: 6px 0px 6px 6px;
      background: @papaGreen;
      color: #fff;
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
    }
  }

  .course-item:last-child{
    border-bottom: none;
  }
</style>