<template>
  <div class="page-course ">
    <div class="section-head pageContent" v-if="spuDetail">
      <div class="head-left">
        <img :src="spuDetail.cover_image_url" alt="">
      </div>
      <div class="head-right">
        <div class="title">{{ spuDetail.name }}</div>
        <div class="course-attribute-list">
          <div class="attribute" v-for="(attribute, attributeIndex) in spuDetail.specs" :key="attribute.name">
            <div class="attribute-title">{{ attribute.name }}</div>
            <div class="attribute-values">
              <template
                      v-for="value in attribute.values"
              >
                <span class="value value-disabled"
                      :key="value"
                      v-if="attributeIndex === spuDetail.specs.length - 1 && !canUseLastLevel.includes(value)"
                >{{ value }}</span>
                <span v-else
                      class="value"
                      :key="value"
                      :class="[activeAttributeArr.includes(value)?'value-active':'']"
                      @click="handleChooseAttribute(attribute.name, value, attributeIndex)"
                >
                 {{ value }}
                    <i v-if="canUseStockStatus[value] ===STOCK_STATUS.STOCK_SHORT" class="stock-status stock-status-short">紧张</i>
                    <i v-if="canUseStockStatus[value] ===STOCK_STATUS.SOLD_OUT" class="stock-status stock-status-out">售罄</i>
                </span>

              </template>
            </div>
          </div>
        </div>
        <div class="course-price" v-if="skuDetail">价格 <span>{{ skuDetail.price }}元</span></div>
        <div class="course-tips" @click="clickToads" v-if="adsGoods.title"><span class="con">{{ adsGoods.title }}</span></div>
        <button class="course-service btn" @click="callService">免费规划课程</button>
        <div class="qr-code" v-show="isShowQrcode">
          <div class="close-btn iconfont icon-papa-close-small" @click="delQrcodeBox"></div>
          <div class="title">立即咨询</div>
          <img class="qr-image" :src="qrImage" alt="">
          <div class="tip">微信扫码添加小助手，咨询课程</div>
        </div>
      </div>
    </div>
    <div class="section-main pageContent">
      <div class="main-tabs">
        <div class="tab-head">
          <div class="tab-head-item" v-for="tab in tabList" :key="tab.id" @click="handleChangeTab(tab.id)">
            <div class="tab-title" :class="activePanelId === tab.id?'tab-title-active':''">{{ tab.title }}</div>
            <div class="tab-bar" v-if="activePanelId === tab.id"></div>
            <div class="trial-tag" v-if="isShowTrialTag&& tab.id === 2">
              免费试听
            </div>
          </div>
        </div>
        <div class="tab-con">
          <template v-if="activePanelId === 1">
            <div class="course-detail-images" v-if="spuDetail">
              <img v-for="(img,index) in spuDetail.description_image_urls" :src="img" :key="`${img}+${index}`" alt="">
            </div>
          </template>
          <template v-else-if="activePanelId === 2">
            <Schedule :schedule-data="scheduleData"></Schedule>
          </template>
          <template v-else-if="activePanelId === 3">
            <teacher v-for="teacher in teacherList"  :teacher-data="teacher" :key="teacher.name"></teacher>
          </template>
          <template v-else-if="activePanelId === 4">
            <largess :largess-data="largessData"></largess>
          </template>
        </div>
      </div>
      <div class="main-tips">
        <div class="tips-title">购课说明</div>
        <div class="tips-content" v-if="spuDetail">
          <div class="tips-item" v-for="(tip, index) in spuDetail.descriptions" :key="index">
            <div class="tip-q"><span class="bar"></span> {{ tip.title }}</div>
            <div class="tip-a">{{ tip.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <float-bar category="ielts"></float-bar>
    <float-ads></float-ads>

  </div>
</template>

<script>
  import Course from './Course.js'

  export default Course
</script>

<style lang="less" scoped>
.qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;
      box-shadow: 0px 0px 10px #ccc;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
        text-align: center;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
        transform: translateX(110px);

      }

      & > .tip {
        font-size: 18px;
        color: #333;
        text-align: center;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
        text-align: center;
      }
    }
  .page-course {
    & > .section-head {
      box-sizing: border-box;
      padding: 40px;
      width: 100%;
      // height: 432px;
      background: #fff;
      border-radius: 28px;
      margin: 30px auto 20px;
      display: flex;
      justify-content: space-between;

      .head-left {
        width: 479px;
        height: 265px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 40px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .head-right {
        flex: 1;
        text-align: left;

        .title {
          font-size: 20px;
          color: #000;
          font-weight: bold;
          margin-bottom: 20px;
          // text-align: center;
        }

        .course-attribute-list {
          .attribute {
            display: flex;
            margin-bottom: 20px;

            .attribute-title {
              font-size: 12px;
              color: #666;
              margin-right: 22px;
            }

            .attribute-values {
              .value {
                box-sizing: border-box;
                cursor: pointer;
                margin-right: 10px;
                padding: 3px 9px;
                border-radius: 10px;
                background: #F2F2F2;
                color: #333333;
                font-size: 14px;
                user-select: none;
                position: relative;
              }

              .value-active {
                color: #fff;
                background: #29d087;
              }

              .value-disabled {
                cursor: not-allowed;
                opacity: 0.5;
              }

              .stock-status{
                position: absolute;
                top: -10px;
                right: 0;
                padding: 2px;
                width: 26px;
                height: 14px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;
                transform: scale(0.8);
                font-style: normal;
                text-align: center;
                border-radius: 2px;
              }
              .stock-status-short::before{
                position: absolute;
                left: -2px;
                top: 6px;
                content: '';
                width: 6px;
                height: 6px;
                transform: rotate(45deg);
                background: #F67942;
                border-radius: 2px;
              }
              .stock-status-short{
                background: linear-gradient(-90deg, #F64A42 0%, #F67942 100%);
              }
              .stock-status-out{
                background: #999999;
              }
              .stock-status-out::before{
                position: absolute;
                left: -2px;
                top: 6px;
                content: '';
                width: 6px;
                height: 6px;
                transform: rotate(45deg);
                background: #999999;
                border-radius: 2px;
              }
            }
          }
        }

        .course-price {
          font-size: 12px;
          color: #666;

          & > span {
            margin-left: 22px;
            font-size: 26px;
            font-weight: bold;
            color: #FF3333;
          }
        }

        .course-tips {

          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FF4848;
          margin: 12px 0 16px;

          span {
            border-radius: 8px;
            padding: 2px 10px;
            background: #FFEDEC;
          }
        }

        .course-service {
          width: 180px;
          height: 42px;
          background: #29d087;
          border-radius: 21px;
          text-align: center;
          line-height: 42px;
          color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          user-select: none;
          cursor: pointer;
          outline: none;
          transition: 0.2s all;
          border: none;
        }
        .btn:hover{
          opacity: 0.8;
        }

        .btn:active {
          transform: scale(0.98);
          opacity: 1;
        }
      }

    }

    & > .section-main {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .main-tabs {
        box-sizing: border-box;
        padding: 0 30px 40px;
        width: 880px;
        border-radius: 28px;
        background: #fff;

        .tab-head {
          display: flex;
          align-items: center;

          .tab-head-item {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 30px 0 0;
            width: 130px;
            height: 80px;
            text-align: center;
            cursor: pointer;
            position: relative;

            .tab-title {
              font-size: 18px;
              color: #666;
            }

            .tab-title-active {
              color: #000;
              font-weight: bold;
            }

            .tab-bar {
              width: 24px;
              height: 8px;
              background: #29D087;
              border-radius: 4px;
            }

            .trial-tag {
              position: absolute;
              top: 8px;
              right: 18px;
              background: #FF4243;
              color: #FFEA5D;
              font-size: 12px;
              padding: 1px 3px;
              border-radius: 10px;
            }

            .trial-tag::after {
              content: '';
              position: absolute;
              bottom: -3px;
              width: 8px;
              height: 8px;
              left: 24px;
              transform: rotate(45deg);
              background: #FF4243;
            }
          }
        }

        .tab-con {
          padding: 20px 0 30px 0;
          .course-detail-images {
            width: 100%;
            font-size: 0;

            img {
              width: 100%;
            }
          }
        }
      }

      .main-tips {
        box-sizing: border-box;
        padding: 30px 30px 40px;
        width: 300px;
        background: #fff;
        border-radius: 28px;
        text-align: left;

        .tips-title {
          color: #000;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .tips-content {
          font-size: 14px;

          .tips-item {
            margin-bottom: 30px;

            .tip-q {
              color: #000;
              font-size: 14px;
              font-weight: bold;
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              margin-right: 8px;
            }

            .bar {
              width: 6px;
              height: 12px;
              background: #29D087;
              border-radius: 3px;
              margin-right: 4px;
            }

            .tip-a {
              color: #666;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
</style>
