<template>
  <div class="teacher">
    <div class="avatar">
      <img :src="teacherData.avatar" alt="">
    </div>
    <did class="desc">
      <div class="teacher-name"><span :class="[ teacherData.course_subject === '写作' ? 'writing' : '', teacherData.course_subject === '阅读' ? 'reading' : '', teacherData.course_subject === '听力' ? 'listening' : '', teacherData.course_subject === '口语' ? 'spoken' : '', teacherData.course_subject === '默认' ? 'default' : ''  ]">{{ teacherData.course_subject }}</span>{{teacherData.name}}{{teacherData.name_cn}}</div>
      <div class="teacher-desc">{{teacherData.introduction}}</div>
    </did>
  </div>
</template>

<script>
  export default {
    name: 'teacher',
    props: {
      teacherData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.listening {
  display: inline-block;
  width: 36px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #ED4B4B;
  background-color: #F7EBEB;
  border-radius: 4px;
  margin-right: 8px;
}

.writing {
  display: inline-block;
  width: 36px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #437BBA;
  background-color: #EBF4F7;
  border-radius: 4px;
  margin-right: 8px;
}

.reading {
  display: inline-block;
  width: 36px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #EDA24B;
  background-color: #F7F3EB;
  border-radius: 4px;
  margin-right: 8px;
}

.spoken {
  display: inline-block;
  width: 36px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #71D4B2;
  background-color: #B6EDDC;
  border-radius: 4px;
  margin-right: 8px;
}

.default {
  display: inline-block;
  width: 36px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  background-color: #667482;
  border-radius: 4px;
  margin-right: 8px;
}
.teacher{
  box-sizing: border-box;
  display: flex;
  margin-bottom: 30px;
  .avatar{
    width: 156px;
    height: 200px;
    background: #F4F5F6;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:20px ;
    flex-shrink: 0;
    img{
      width: 100%;
    }
  }
  .desc{
    text-align: left;
    .teacher-name{
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
      
    }
    .teacher-desc{
      white-space: break-spaces;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
    }
  }

}
</style>