<template>
  <div class="class-schedule">
    <div v-if="classSchedule.length > 0">
      <el-collapse v-model="activeNames">
        <template v-for="item in classSchedule">
          <el-collapse-item :title="item.title" :name="item.id" :key="item.id">
            <div class="itemBox">
              <template v-for="(child, index) in item.sections">
                <course-item :key="index + child.id" :course-data="child" :course-index="index"></course-item>
              </template>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </div>
    <NoContent v-else :data="noContentData"></NoContent>
  </div>
</template>

<script>
  import ClassScheduleJs from './List.js'

  export default ClassScheduleJs
</script>

<style lang="less" scoped>
  /*@import '../../assets/style/lib-base.less';*/

  .el-collapse {
    border-top: none;
  }

  .class-schedule {
    .itemBox {
      :last-child {
        border: none;
      }
    }
  }
</style>
