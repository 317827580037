import NoContent from '@/components/NoContent/NoContent.vue'
import CourseItem from '@/views/Course/components/schedule/course-item.vue'
import { mapActions, mapState } from 'vuex'
import * as Api from '@/api/common/common'
import { jumpPage } from '@/utils/utils'
import { courseWatch } from '@/api/common/common'
import { getPlayId } from '@/api/course/course'
import moment from 'moment'

export default {
  components: {
    NoContent,
    CourseItem
  },
  props: {
    classSchedule: {
      type: Array,
      default: [],
    },
  },
  data () {
    return {
      activeNames: 1,
      noContentData: {
        pic: 'schedule_pic',
        tip: '课程表还没有安排好哦',
      },
    }
  },
  computed: {
    ...mapState({
      videoDialog: ( state ) => state.user.videoDialog,
      isLogin: ( state ) =>
          state.user.authorization && state.user.authorization.access_token,
    }),
  },
  methods: {
    showVideo ( child ) {
      if (this.isLogin) {
        this.setCurrentVideo(child)
        // todo 免费视频跳知乎
        this.setVideoDialog(true)
      } else {
        this.$message.error('请登录后观看~')
        this.setLoginDialog({ status: true })
      }
    },
    formatSecond ( second ) {
      let minutes = moment.duration(second, 'seconds').get('minutes')
      let hour = moment.duration(second, 'seconds').get('hour')
      if (hour) {
        return hour + '小时' + minutes + '分钟'
      } else {
        return minutes + '分钟'
      }
    },

    openPage ( url ) {
      jumpPage(url)
    },
    ...mapActions(['setVideoDialog', 'setCurrentVideo', 'setLoginDialog']),
  },
}
