<template>
  <div class="c-schedule">
    <div class="tab">
      <div class="tab-head">
        <div class="tab-switch">
          <div class="switch-item" :class="activePanelId===1?'switch-item-left':'switch-item-right'"></div>
          <div class="switch-left switch-item" :class="activePanelId===1?'switch-item-active':''"
               @click="handleChooseSwitch(1)">
            按科目顺序
          </div>
          <div class="switch-right switch-item" :class="activePanelId===2?'switch-item-active':''"
               @click="handleChooseSwitch(2)">
            按时间顺序
          </div>
        </div>
      </div>
      <div class="tab-body">
        <template v-if="activePanelId === 1">
          <ClassSchedule class="body-container  schedule-view" :class-schedule="scheduleData"></ClassSchedule>
        </template>
        <template v-if="activePanelId === 2">
          <div class=" time-view">
            <div class="body-container trial-course" v-if="trialCourses.length>0">
              <course-item v-for="(course, index) in trialCourses" :key="course.id" :course-data="course" :courseIndex="index"></course-item>
            </div>
            <div class="body-container normal-course">
              <course-item v-for="(course, index) in normalCourse" :key="course.id" :course-data="course" :courseIndex="index"></course-item>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
  import ClassSchedule from './List/List.vue'
  import { COURSE_MODEL } from '@/enums/API'
  import CourseItem from '@/views/Course/components/schedule/course-item.vue'

  export default {
    name: 'schedule',
    components: {
      ClassSchedule,
      CourseItem
    },
    data () {
      return {
        activePanelId: 1,
        trialCourses: [],
        normalCourse: []
      }
    },
    props: {
      scheduleData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      calendarData () {
        let arr = []
        this.scheduleData.forEach(item => {
          arr = arr.concat(item.sections)
        })
        return arr
      }
    },
    methods: {
      handleChooseSwitch ( tab ) {
        this.activePanelId = tab
      },
      dealScheduleData ( courseArr ) {
        let trialArr = []  //试听课数组
        let normalArr = []  // 非试听课数组
        courseArr.forEach(course => {
          if (course.course_mode === COURSE_MODEL.TRIAL) {
            trialArr.push(course)
          } else {
            normalArr.push(course)
          }
        })
        this.trialCourses = trialArr.sort(function ( a, b ) {
          return a.started_at - b.started_at
        })
        this.normalCourse = normalArr.sort(function ( a, b ) {
          return a.started_at - b.started_at
        })
      }
    },
    watch: {
      scheduleData: {
        deep: true,
        handler ( newData ) {
          let arr = []
          newData.forEach(item => {
            arr = arr.concat(item.sections)
          })
         this.dealScheduleData(arr)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
// ::v-deep div[role="tab"] {
//   :last-child {
//     border-bottom-color: red ;
//   }
// }
  ::v-deep .el-collapse-item {
      &:last-child {
        .el-collapse-item__header {
          border: none;
        }
      }
      :first-child{
        font-weight: 700;
      }
  }
  ::v-deep .el-collapse-item__header {
 
    :first-child{
      color: #ccc;
    }
  }
  .tab-head {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tab-switch {
      box-sizing: border-box;
      padding: 0 2px;
      width: 204px;
      height: 40px;
      background: #EBEBEB;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switch-item {
        width: 100px;
        height: 36px;
        font-size: 12px;
        color: #666;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        transition: 0.3s all;
        position: relative;
      }

      .switch-item-active {
        color: #29d087;
      }

      .switch-item-left {
        position: absolute;
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background: #FFFFFF;
        transform: translateX(0px);
      }

      .switch-item-right {
        position: absolute;
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background: #FFFFFF;
        transform: translateX(100px);
      }
    }
  }

  .tab-body {
    .schedule-view {

    }
    .body-container{
      box-sizing: border-box;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 8px;
    }
    .trial-course{
      margin-bottom: 20px;
    }
  }
</style>