<template>
  <div class="largess">
    <div class="largess-item"
         v-for="item in largessData"
         :key="item.title"
    >
      <div class="head">
        <div class="icon">
          <img :src="item.icon_image_url" alt="">
        </div>
        <div class="title">{{item.title}}</div>
        <div class="second-title">{{item.subtitle}}</div>
      </div>
      <ul class="con">
        <!-- {{item.description}} -->
        <li v-for="(item, index) in description" :key="index">{{ item }}</li>
      </ul>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'largess',
    props: {
      largessData: {
        type: Object,
        default: ()=>{
          return {}
        }
      }
    },
    computed: {
      description() {
        let descriptionArr
        this.largessData.forEach(item => {
          descriptionArr = item.description.split('\n')
        })
        return descriptionArr
      }
    }
  }
</script>

<style lang="less" scoped>
  .largess{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .largess-item{
      box-sizing: border-box;
      padding: 30px;
      width: 400px;
      // height: 176px;
      background: #F5F5F5;
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      margin-bottom: 20px;
      .head{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .icon{
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
          img{
            width: 100%;
          }
        }
        .title{
          font-size: 18px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #000000;
          line-height: 26px;
          margin: 0 10px;
        }
        .second-title{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0,0,0,0.7000);
          line-height: 22px;
        }
      }
      .con{
        padding-left: 20px;
        text-align: left;
        // white-space: pre-wrap;
        // li {
        //   list-style-type: square;
        // }
        li::marker {
          color: #5e5e5e;
          font-size: 22px;
          border-radius: 2px 2px 2px 2px;
        }
      }
    }
  }

</style>