import * as Api from '@/api/index/index'
import _ from 'loadsh'
import Schedule from '@/views/Course/components/schedule/schedule.vue'
import Teacher from '@/views/Course/components/teacher.vue'
import Largess from '@/views/Course/components/largess/largess.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import { resolveAddress } from '@/utils/utils'
import { COURSE_MODEL, STOCK_STATUS } from '@/enums/API'
import FloatBar from '@/components/FloatBar/FloatBar.vue'
import FloatAds from '@/components/FloatAds/FloatAds.vue'


export default {
    name: 'Course',
    data() {
        return {
            spuDetail: null,
            skuList: [],
            specList: [],
            activePanelId: 1,
            activeAttributeArr: [],
            canUseLastLevel: [],
            canUseStockStatus: {},
            teacherList: [],
            largessData: [],
            tabList: [{
                    id: 1,
                    title: '课程详情',
                    component: ''
                },
                {
                    id: 2,
                    title: '课程表',
                    component: ''
                },
                {
                    id: 3,
                    title: '课程师资',
                    component: ''
                },
                {
                    id: 4,
                    title: '特别加赠',
                    component: ''
                }
            ],
            skuDetail: null,
            scheduleData: [],
            isShowTrialTag: true, // 抢先听tag
            STOCK_STATUS,
            category: 'ielts',
            isShowQrcode: false,
            categoryData: [],
            qrImage: '',
            adsPath: '',
            adsGoods: {}
        }
    },
    components: {
        Schedule,
        Teacher,
        Largess,
    NavBar,
    FloatBar,
      FloatAds
  },
  props: ['spuId', 'skuId'],
  mounted () {
    this.getSpuDetails()
        this.getSkuDetail()
        this.getAds()
  },
    updated() {},
    methods: {
        getSpuDetails() {
            Api.spuDetailApi(this.spuId, res => {
                this.spuDetail = res
                this.skuList = res.skus
                this.specList = res.specs
                this.findSku()
            })
        },
        handleChooseAttribute(key, value, index) {
            let targetArr = this.activeAttributeArr
            targetArr.splice(index, 1, value)
            let targetPath = targetArr.join(';')
            let count = 0
            this.skuList.forEach(sku => {
                if (sku.path.includes(targetPath)) {
                    this.$router.replace({
                        name: this.$route.name,
                        params: {
                            spuId: this.spuId,
                            skuId: sku.id
                        }
                    })
                } else {
                    count++
                }
            })
            if (count === this.skuList.length) {
                let target = this.activeAttributeArr.splice(this.activeAttributeArr.length - 1, 1)
                this.canUseLastLevel.forEach((item, index) => {
                    if (item === target[0]) {
                        this.canUseLastLevel.splice(index, 1)
                    }
                })
            }
        },
        findSku() {
            this.skuList.forEach((sku) => {
                if (sku.id === parseInt(this.skuId)) {
                    this.activeAttributeArr = sku.path.split(';')
                    this.specStr()
                }


            })
        },
        specStr() {
            this.canUseLastLevel = []
            this.canUseStockStatus = {}
            let activeAttributeArr = _.cloneDeep(this.activeAttributeArr)
            activeAttributeArr.splice(activeAttributeArr.length - 1, 1)
            let activeAttributeStr = activeAttributeArr.join(';')
            this.skuList.forEach(sku => {
                if (sku.path.includes(activeAttributeStr)) {
                    let arr = sku.path.split(';')
                    this.canUseLastLevel.push(arr[arr.length - 1])
                    this.canUseStockStatus[arr[arr.length - 1]] = sku.stock_status
                }
            })

        },
        checkCanChoose() {


        },
        handleChangeTab(id) {
            this.activePanelId = id
            if (id === 2) {
                if (this.scheduleData.length > 0) return
                this.getCourseList()

            }
            if (id === 3) {
                if (this.teacherList.length > 0) return
                this.getTeacherList()
            }
            if (id === 4) {
                if (this.largessData.length > 0) return
                this.getLargess()
            }
        },
        getSkuDetail() {
            Api.skuDetailApi(this.skuId, res => {
                this.skuDetail = res
                this.isShowTrialTag = res.is_exist_audition
                if (this.activePanelId === 3) {
                        this.getTeacherList()
                }
            })
        },
        getCourseList() {
            const params = {
                course_ids: this.skuDetail.course_ids
            }
            Api.courseSyllabusApi(params, res => {
                res.forEach(section => {
                    section.sections.forEach(course => {
                        if (course.course_mode === COURSE_MODEL.TRIAL) {
                            this.isShowTrialTag = true
                        }
                    })
                })
                this.scheduleData = res
            })
        },
        getTeacherList() {
            Api.courseTeachers(this.skuDetail.course_ids, res => {
                this.teacherList = res
            })
        },
        getLargess() {
            Api.spuLargess(this.spuId, res => {
                console.log(res)
                this.largessData = res
            })
        },
        callService() {
            this.categoryData = this.$store.state.common.categoryTypes
            this.categoryData.forEach(item => {
                if (item.id === this.spuDetail.category_id) {
                    this.isShowQrcode = true
                    this.qrImage = item.qrcode_image_url
                }
            })
        },
        delQrcodeBox() {
            this.isShowQrcode = false
        },
        clickToads() {
            if (this.adsPath.indexOf('http') > -1) {
                window.open(this.adsPath)
            } else {
                window.location.href = this.adsPath
            }
        },
        getAds() {
            let params = {
                position: 25,
            }
            Api.ads(params, (res) => {
                res.forEach(item => {
                    console.log(item);
                    this.adsGoods = item
                    this.adsPath = resolveAddress(item)
                    console.log(this.adsPath)
                })
            })

        },
    },
    watch: {
        skuId(newID) {
            if (newID) {
                this.getSkuDetail()
                this.findSku()
                if (newID && this.activePanelId === 2) {
                    this.getCourseList()
                }

            }
        }
      }
  }
